<template>
	<!-- notranslate class is needed to prevent browser extensions from breaking the textbox -->
	<!-- @dragstart.prevent needed to prevent default link dragging behavior -->
	<GridTextBox
		class="notranslate"
		data-qa="builder-gridelement-text"
		@dragstart.prevent
	>
		<EditorContent
			v-if="localEditor"
			:editor="localEditor"
		/>
	</GridTextBox>
</template>

<script>
import { EditorContent } from 'tiptap';
import { mapState } from 'vuex';

import { useTextEditor } from '@/use/text-editor/useTextEditor';
import GridTextBox from '@user/components/grid-components/textBox/GridTextBox.vue';

export default {
	components: {
		GridTextBox,
		EditorContent,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
	},
	setup() {
		const {
			createLocalEditor,
			setCurrentEditor,
			unsetCurrentEditor,
			localEditor,
			saveChanges,
			setCaretPositionToEnd,
		} = useTextEditor();

		return {
			createLocalEditor,
			setCurrentEditor,
			unsetCurrentEditor,
			localEditor,
			saveChanges,
			setCaretPositionToEnd,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapState('gui', ['isEditMode']),
		isTextEditMode: ({
			id,
			currentElementId,
			isEditMode,
		}) => id === currentElementId && isEditMode,
	},
	watch: {
		isTextEditMode(value) {
			if (value) {
				this.setCurrentEditor();
				this.setCaretPositionToEnd();
			} else {
				this.saveChanges(this.id);
				this.unsetCurrentEditor();
			}
		},
	},
	mounted() {
		/**
		 * Short-term hack to comply with old text editor mess. Old editor added unneeded <br>
		 * tags at the end of some <p> tags and new text editor treats them as a new space.
		 * Will add a mapper for better, long-term fix, but right not user-app is stuck and this PR.
		 * grew out to be way too big to be held off any more.
		 * TODO: https://github.com/zyro-inc/zyro/issues/3682
		 */
		this.createLocalEditor(this.id, this.data.content.replace(/<br><\/p>$/, '</p>'));
	},
};
</script>
